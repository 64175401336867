import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Trbm from "@tightrope/trbm"
import CtaDisclosure from "@tightrope/ctadisclosure"
import CtaDisclosureSearch from "@tightrope/ctadisclosuresearch"
import {isIeEdge,isChrome,isFirefox} from "@tightrope/lpscripts/browserdetect"

const prdbestData = import("./data/data.json");

const Img = () => <img className="cws" src="/img/cws-badge.png" />;

export default function prdbest() {

  let module;
   module = null;
  if(isChrome() || isIeEdge()){
     module = <CtaDisclosureSearch  data={prdbestData}></CtaDisclosureSearch>
   }
   if(isFirefox()){
     module = <CtaDisclosure data={prdbestData}></CtaDisclosure>
   }

   let css;
     if(isFirefox()){
       css = `.trbm-module--trbm .trbm-module--bullets {
                width: 55%;
                font-weight: lighter;
                padding-left: 20px;
              }
       .cws {
         display: none;
       }
         .trbm-module--trbm button {
           padding: 20px 80px!important;
           font-weight: normal;
         }
       #ctadisclosure-module--ctadisclaimer {
           padding: 25px 7% 0;
         }

         .trbm-module--trbm .trbm-module--steps {
           display: block;
}
       }`;
     }
     else{
       css = `.trbm-module--trbm .trbm-module--bullets {
         padding-left: 0;
       }
       .cws {
         width: 200px;
         display: block;
         margin: 10px auto;
       }
       #ctadisclosure-module--ctadisclaimer {
         padding: 0px 7%;
         padding-bottom: 0;
      }

      .trbm-module--trbm .trbm-module--steps {
        display: none;
      }
      .trbm-module--trbm .trbm-module--bullets {
        padding-left: 25px;
      }`;
     }

  return(
    <HomepageLayout>
      <Helmet>
      <style type="text/css">
      {`
        body {
          background-image: url(/assets/beefsmall.jpg);
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
          background-attachment: fixed;
          overflow: hidden;
        }
        h1 {
          margin: 0 auto !important;
        }
        .trbm-module--logo {
          margin: 0px 0px 10px !important;
          width: 130px !important;
        }

        .trbm-module--trbm button {
          background: #F1640C;
          padding: 15px 50px;
        }

        .trbm-module--card {
          padding: 35px!important;
        }

        #ctadisclosuresearch-module--ctadisclaimersearch {
          padding: 0px 7%;
          padding-bottom: 0;
        }

        .trbm-module--trbm h2 {
          margin-top: 7px;
          font-weight: normal;
        }

        .trbm-module--trbm .trbm-module--wrap {
            border: 2px solid #333;
            height: 100%;
            padding: 10px 0;
        }
        .footerlinks-module--br, .footerlinks-module--br a {
          color: #f1f1f1;
        }
      `}
         </style>

         <style type="text/css">
           {css}
               </style>
      <title>Freshy Search - freshysearch.com</title></Helmet>
      <Trbm data={prdbestData}><Img />{module}</Trbm>
    </HomepageLayout>
  )
}
